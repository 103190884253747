export default {
  name: 'CustomFormList',
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      form: null,
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false
    };
  },
  props: {
    data: {
      default: null
    }
  },
  computed: {
    tableHeaders () {
      const headers = [
        { key: 'created_at', label: this.$t('general.item.created_at'), type: 'datetime' }
      ];
      if (this.canEdit) {
        headers.unshift({ key: 'user.fullname', label: this.$t('user.name') });
      }
      if (this.formTypeCode === 'HOLIDAY') {
        headers.push({ key: 'model.fromDate', label: this.$t('custom-form.form-field.HOLIDAY.fromDate'), type: 'date' })
        headers.push({ key: 'model.toDate', label: this.$t('custom-form.form-field.HOLIDAY.toDate'), type: 'date' })
        headers.push({ key: 'model.paidHolidays', label: this.$t('custom-form.form-field.HOLIDAY.paidHolidays') })
      }
      const actions = [];
      actions.push({
        label: this.$t('custom-form.record.action.download'),
        emit: 'downloadPdf',
        bicon: 'download',
        class: 'btn-info',
        ifconfig: { param: 'file', value: null, notCheck: true }
      });
      actions.push({ label: this.$t('custom-form.record.action.view'), emit: 'editItem', bicon: 'eye-fill', class: 'btn-secondary' });
      headers.push({ key: 'actions', label: '', actions: actions });
      return headers;
    },
    formTypeCode () {
      if (this.form) {
        return this.$store.getters.getParamFromConfigByFilter('custom_form_types', 'id', parseInt(this.form.type_id), 'code');
      }
      return null;
    },
    tableExpandConfig () {
      return null;
    },
    requestPath () {
      let path = 'custom-form/form';
      if (this.data && this.data.id) {
        path += '/view/' + this.data.id + '/records/list'
      } else {
        path += '/list-records';
      }
      path += '?perPage=' + this.paging.limit + '&page=' + this.paging.page;

      return path;
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-custom-forms');
    },
    tableActions () {
      const actions = { download: true };
      actions.add = { title: this.$t('general.actions.add') };
      return actions;
    }
  },
  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.loading = true;
      const self = this;
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, function (response) {
        if (response.data) {
          self.tableItems = response.data.items;
          self.form = response.data.form;
          self.paging = response.paging;
          self.loading = false;
        }
      });
    },
    editItem (el, newtab = 0) {
      let id = 'NEW';
      if (el) {
        id = el.id;
      }
      const routeData = this.$router.resolve({ name: 'menu.forms.record-edit', params: { formid: el.form_id, recordid: id } });
      if (newtab) {
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push(routeData.href);
      }
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    downloadPdf (el) {
      el.loading = true;
      this.$refs.tableview.$forceUpdate();
      const path = 'custom-form/form/view/' + el.form_id + '/records/view/' + el.id + '/download';
      const self = this;
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_DOWNLOAD, [], function (response) {
        if (response.error) {
          self.ApiRequest.displayErrorDialog(response, self);
        }
        el.loading = false;
        self.$refs.tableview.$forceUpdate();
      });
    }
  },
  mounted () {
    this.getTableData();
    this.isMounted = true;
  }
}
