import UserListExpand from '@/components/user/listexpand/UserListExpand.vue';
import moment from 'moment';

export default {
  name: 'InstructionsList',
  components: { UserListExpand },
  props: {
    params: {
      type: Object,
      default: null
    },
    userId: {
      type: Number | String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      instructions: [],
      content: null,
      moodleLoginOpen: null
    }
  },
  methods: {
    loadInstructions (forceLoad = false) {
      if (this.userIdCalculated || forceLoad) {
        this.loading = true;
        let path = 'services/moodle/courses/';
        path = path + '?show_moodle_link=' + this.showMoodleLink;
        path = path + (this.userIdCalculated ? `&userid=${this.userIdCalculated}` : '');
        this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, [], (response) => {
          if (response.data) {
            this.instructions = response.data.courses;
          }
          this.loading = false;
        });
      }
    },
    getCompletedDate (data) {
      if (data && data > 0) {
        return moment.unix(data).format('DD.MM.YYYY HH:mm:ss')
      } else {
        return '-';
      }
    },
    getStatus (data) {
      return data === true ? this.$t('instructions.statuses.completed') : this.$t('instructions.statuses.inprogress');
    },
    openUrl (item) {
      // moodle's one time login can be used only once,
      // so after user is logged in user must open links not containing moodle login
      if (this.moodleLoginOpen || this.$session.get('moodle_login_open')) {
        window.open(item.moodle_url, '_blank');
      } else {
        window.open(item.moodle_login_url, '_blank');
      }
      this.moodleLoginOpen = true;
      this.$session.set('moodle_login_open', true);
    }
  },
  computed: {
    tableHeaders () {
      const headers = [
        { key: 'fullname', label: this.$t('instructions.name'), type: 'slot', slotcode: 'fullname_link' },
        { key: 'completed_on', label: this.$t('instructions.completed_on'), format: 'formatFunc', funcs: [this.getCompletedDate] },
        { key: 'status', label: this.$t('instructions.status'), format: 'formatFunc', funcs: [this.getStatus] }
      ];
      if (this.showMoodleLink) {
        headers.push({ key: 'link', label: this.$t('instructions.link'), type: 'slot', slotcode: 'link_link' });
      }
      headers.push({
        key: 'actions',
        label: '',
        class: 'col-width-medium'
      });
      return headers;
    },
    showMoodleLink () {
      if (this.params && this.params.showMoodleLink === false) {
        return false;
      } else {
        return true;
      }
    },
    userIdCalculated () {
      return this.userId ?? this.$session.get('userId');
    }
  },
  watch: {
    userId: {
      immediate: true,
      handler (val) {
        this.loadInstructions();
      }
    }
  },
  mounted () {
    // if userid will be provided wait for it in watcher
    // otherwise call loadInstructions thus loading instructions for current user
    if (!(this.params && this.params.provideUserId === true)) {
      this.loadInstructions(true);
    }
  }
}
