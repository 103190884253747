import BasicHelper from 'ebg-vue-common/src/helpers/BasicHelper.js';

export default {
  name: 'Explanation',
  methods: {
    BasicHelper () {
      return BasicHelper
    }
  },
  props: {
    text: {
      type: Object,
      default: null
    },
    size: {
      type: String,
      default: 'fs-1'
    },
    showAsTooltip: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    userCompanyId () {
      return this.$session.get('company_id');
    },
    title () {
      if (this.text && this.text[this.userCompanyId]) {
        return BasicHelper.getTranslation(this.text[this.userCompanyId]);
      }
      return BasicHelper.getTranslation(this.text);
    }
  }
}
