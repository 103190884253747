import Explanation from '@/components/common/explanation/Explanation.vue';

export default {
  name: 'ApplicationFormsIndex',
  components: { Explanation },
  data () {
    return {
      isLoading: false,
      currentReq: null,
      tableSorting: { item: 'order', direction: 'asc' },
      isMounted: false,
      forms: null,
      formModels: { holiday: ['HOLIDAY_ANNUAL', 'HOLIDAY_OTHER'], advance: ['ADVANCE_PAYMENT'] },
      selectedFormType: null
    };
  },
  methods: {
    loadForms (model) {
      this.selectedFormType = model;
      let path = 'custom-form/form/list/?order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      if (this.formModels[model]) {
        path = path + '&form_models=' + this.formModels[model];
      }
      this.isLoading = true;
      this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.data) {
          this.forms = response.data;
          this.paging = response.paging;
          this.isLoading = false;
        }
      });
    },
    navigateUrl (form) {
      this.$router.push({ name: 'menu.forms.record-edit', params: { formid: form.id, recordid: 'NEW' } });
    }
  },
  computed: {
    formType () {
      return this.$route.query.form_type;
    }
  },
  mounted () {
    if (this.formType && Object.keys(this.formModels).includes(this.formType)) {
      this.loadForms(this.formType);
    }
    this.isMounted = true;
  }
}
