import { render, staticRenderFns } from "./CustomFormList.vue?vue&type=template&id=3c528fa3"
import script from "./CustomFormList.js?vue&type=script&lang=js&external"
export * from "./CustomFormList.js?vue&type=script&lang=js&external"
import style0 from "./CustomFormList.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports