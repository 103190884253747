const defaultFilterValues = {
  created_at: null,
  payout_type: null,
  statuses: [],
  companies: []
}

export default {
  name: 'AdvancePaymentReport',
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'created_at', direction: 'desc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 }
    }
  },
  props: {
  },
  components: {
  },
  computed: {
    tableHeaders () {
      const headers = [
        { key: 'created_at', type: 'datetime', label: this.$t('reports.advance-payment.created_period'), filterKey: 'created_at' }
      ];
      headers.push({ key: 'user.fullname', label: this.$t('user.name'), filterKey: 'userFullName' });
      headers.push(
        { key: 'user.axapta_d365_code', label: this.$t('reports.advance-payment.user-id') },
        { key: 'amount', label: this.$t('reports.advance-payment.amount') },
        {
          key: 'payout_type',
          label: this.$t('reports.advance-payment.payout-type'),
          format: 'formatFromConfig',
          configKey: 'custom_form_constants',
          configParam: 'name',
          filterKey: 'payout_type'
        },
        {
          key: 'record.status_id',
          label: this.$t('reports.advance-payment.record-status'),
          format: 'formatFromConfig',
          configKey: 'custom_form_record_statuses',
          configParam: 'name',
          filterKey: 'statuses'
        }
      );
      headers.push({ key: 'actions', actions: [] });

      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      const filters = { };
      filters.created_at = { type: 'date-range', label: this.$t('reports.advance-payment.filters.created'), colOnlyFilter: true };
      filters.statuses = { type: 'checkbox', label: '', options: this.applicationStatuses, colOnlyFilter: true }
      filters.payout_type = {
        type: 'select',
        label: this.$t('reports.advance-payment.filters.record-status'),
        options: this.payoutTypes,
        colOnlyFilter: true
      };
      if (this.ApiRequest.userHasPermission('view-advance-payment-requests-all-companies')) {
        filters.companies = {
          type: 'checkbox',
          label: this.$t('company-structure.company.name'),
          colOnlyFilter: false,
          options: this.companies,
          class: 'col-md-8'
        }
      }
      filters.userFullName = {
        type: 'text',
        colOnlyFilter: true,
        label: this.$t('user.name'),
        class: 'col-md-2'
      }

      return filters;
    },
    tableActions () {
      return { download: true };
    },
    requestPath () {
      let path = 'custom-form/form/holiday-requests/list/ALL';
      if (this.filterValues.filter) {
        path += '/' + this.filterValues.filter;
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
      }
      path += '?perPage=' + this.paging.limit + '&page=' + this.paging.page + '&order=created_at,DESC';
      path += '&requestType=advance&mode=report';
      return path;
    },
    companies () {
      return this.$store.getters.getConfigOptions('user_companies', this.$i18n.locale, 'disabled', '0');
    },
    payoutTypes () {
      const formConstantTypeId = this.$store.getters.getParamFromConfigByFilter('custom_form_constant_types', 'code', 'ADVANCE_PAYMENT_TYPE', 'id');
      return this.$store.getters.getConfigOptions('custom_form_constants', this.$i18n.locale, 'const_type_id', formConstantTypeId);
    },
    applicationStatuses () {
      return this.$store.getters.getConfigOptions('custom_form_record_statuses', this.$i18n.locale);
    }
  },
  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.loading = true;
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, this.filterValues, (response) => {
        if (response.data) {
          this.tableItems = response.data;
          this.paging = response.paging;
        }
        this.loading = false;
      }, true);
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    getUserCodeFromJobPositions (positions) {
      const primaryJobPosition = positions.filter(
        el => el.isPrimary === true
      )[0];

      return primaryJobPosition.axaptaCode ?? primaryJobPosition.d365Code;
    },
    download (html, exportCols) {
      const path = this.ApiRequest.addGetParamsToPath(this.requestPath, this.filterValues);
      this.$refs.tableview.downloadTableData(path, exportCols);
    }
  },
  mounted () {
    this.getTableData();
  }
}
