import { mapState } from 'vuex';
import moment from 'moment';
import BasicHelper from 'ebg-vue-common/src/helpers/BasicHelper.js';

export default {
  name: 'AxaptaSalaryCard',
  props: {
    isModal: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      loading: false,
      cardId: null,
      card: null,
      reliefMessage: null
    }
  },
  computed: {
    ...mapState(['config']),
    componentName () {
      if (this.isModal) {
        return 'b-modal';
      }
      return 'div';
    },
    cardGroups () {
      return this.BasicHelper.sortArrayByKeyValue(
        Object.values(this.$store.getters.getItemsFromConfigWithFilter('axapta_salary_card_item_groups', 'displayInCard', '1')),
        'order'
      );
    },
    requestPath () {
      return 'axapta/salary-card/card/' + this.cardId + '/view';
    },
    title () {
      return this.$t('axapta.salary-card.title');
    }
  },
  methods: {
    setData (cardId) {
      this.cardId = cardId;
      this.reliefMessage = BasicHelper.getTranslation(this.$store.getters.getParamFromConfigByFilter('texts', 'code', 'AXAPTA_SALARY_CARD_ATVIEGLOJUMI', 'name'));
      this.getTableData();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    close () {
      this.$refs['modal-window'].hide();
    },
    getTableData () {
      const self = this;
      self.loading = true;
      this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, [], function (response) {
        if (response.data) {
          self.card = response.data;
        }
        self.loading = false;
      })
    },
    showReliefMessage (groupId) {
      return !(this.card.job_position.job_position.company.isDynamics) &&
              this.$store.getters.getParamFromConfigByFilter('axapta_salary_card_item_groups', 'id', groupId, 'code') === 'RELIEF' &&
              moment(this.card.date).isSameOrAfter('2024-09-01')
      ;
    }
  }
}
