import FormRequestList from '@/components/customform/requests/list/FormRequestList.vue';

export default {
  name: 'UserHoliday',

  data () {
    return {
      isLoading: false,
      remainHoliday: null
    }
  },

  components: {
    FormRequestList
  },

  computed: {
    formUrlParams () {
      return { name: 'menu.user.application-forms', query: { form_type: 'holiday' } };
    }
  },

  methods: {
    async show () {
      this.isLoading = true;
      const { data } = await this.loadData(`custom-form/form/holiday-requests/view/${this.$session.get('uuid')}`);
      this.remainHoliday = data.primary_job_position.remainHoliday;
      this.isLoading = false;
    },

    async loadData (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, this.form, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  },

  mounted () {
    this.show()
  }
}
