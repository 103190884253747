import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import CustomFormFields from '@/components/common/customform/form/fields/CustomFormFields.vue'
import NotificationEditList from '@/components/common/customform/form/notifications/editlist/EditList.vue';

const defaultEl = {
  id: null,
  name: {},
  model_class_id: null,
  code: '',
  default_fields: [],
  notifications: {}
};

export default {
  name: 'CustomFormTypeEdit',
  data () {
    return {
      editEl: defaultEl,
      recipients: null,
      options: [],
      loading: false,
      showNotifications: false,
      emailTemplates: []
    }
  },
  validations: {
    editEl: {
      name: { required },
      code: { required }
    }
  },
  components: {
    CustomFormFields,
    NotificationEditList
  },
  computed: {
    getTitle () {
      if (this.editEl.id) {
        return this.$t('menu.forms.type.edit');
      }
      return this.$t('menu.forms.type.new');
    },
    canEdit () {
      return (!this.loading && (this.ApiRequest.userHasPermission('edit-custom-forms')));
    },

    getModelList () {
      const options = this.$store.getters.getConfigOptions(
        'custom_form_models', this.$i18n.locale, null, null, false, false, false, ['class_id']
      ).map((el) => {
        return {
          value: el.class_id,
          text: el.text,
          code: el.code
        }
      });
      options.unshift({ value: null, text: '', code: null })
      return options;
    },

    tabOptions () {
      const options = [
        { code: 'fields', text: this.$t('custom-form.form.fields') }
      ];
      if (this.showNotifications && this.recipients !== null) {
        options.push({ code: 'notifications', text: this.$t('custom-form.notifications.recipients-tab-title') })
      }
      return options;
    }
  },
  methods: {
    show (elData) {
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
      }
      if (!this.editEl.notifications || this.editEl.notifications.length === 0) {
        this.editEl.notifications = {};
      }
      this.options = []; // reset options for new edit
      this.notifications = false;
      this.showNotifications = false;
      this.recipients = null;
      this.loadNotificationData(this.editEl.id);
      if (this.editEl.model_class_id) {
        this.loadModelOptions(this.editEl.model_class_id, false);
      }
      this.v$.$reset();
      this.$refs['modal-window'].show();
    },

    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }

        this.ApiRequest.request('custom-form/type/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, self, { 'item.code': 'general.item.code' });
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },

    close () {
      this.$refs['modal-window'].hide();
    },

    changeModel (id) {
      if (!id) {
        const deleted = [];
        this.editEl.default_fields.forEach((el, i) => {
          if (this.editEl.default_fields[i].id) {
            this.editEl.default_fields[i].deleted = true;
            deleted.push(this.BasicHelper.cloneObject(this.editEl.default_fields[i]));
          }
        });
        this.editEl.default_fields = deleted;
        this.options = [];
        this.notifications = false;
        this.v$.$reset();
        return;
      }
      this.loadModelOptions(id, true);
    },
    loadNotificationData (formTypeId = null) {
      let path = 'custom-form/type/recipients';
      if (formTypeId) {
        path = path + `?form_type_id=${formTypeId}`;
      }
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, { }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this, { 'item.code': 'general.item.code' });
        } else {
          this.recipients = response.data;
        }
      });
    },
    updateNotifications (checklist) {
      this.editEl.notifications = Object.assign({}, this.editEl.notifications, checklist);
    },
    loadModelOptions (id, prefill) {
      this.loading = true;
      this.ApiRequest.request('custom-form/type/fields?id=' + id, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (prefill) {
          const options = this.BasicHelper.cloneObject(response.data.fields);
          options.forEach((item, i) => {
            options[i].name = {};
            options[i].name[this.$i18n.locale] = this.$t('custom-form.codes.' + item.code);
          });
          this.editEl.default_fields = options;
        }
        this.options = this.BasicHelper.cloneObject(response.data.fields);
        this.showNotifications = response.data.notifications || false;
        this.emailTemplates = response.data.email_templates || [];
        this.loading = false;
      });
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
