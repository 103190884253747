import CustomFormRecordEdit from '@/components/common/customform/record/edit/CustomFormRecordEdit.vue';

export default {
  name: 'FormRequestList',
  components: { CustomFormRecordEdit },

  props: {
    data: {
      default: null
    },
    onlyMyRequest: {
      type: Boolean,
      default: false
    },
    formType: {
      type: String,
      default: 'holiday'
    }
  },

  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      form: null,
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false,
      filterValues: {
        userFullName: '',
        statuses: [],
        filter: null,
        companies: []
      }
    };
  },

  computed: {
    tableHeaders () {
      const headers = [];
      if ((this.canViewAll || this.canShow) && !this.onlyMyRequest) {
        headers.unshift({ key: 'user.fullname', label: this.$t('user.name') });
      }

      headers.push({ key: 'created_at', label: this.$t('general.item.created_at'), type: 'date' });
      if (this.formType === 'holiday') {
        headers.push(
          { key: 'fromDate', label: this.$t('custom-form.form-field.HOLIDAY.fromDate'), type: 'date' },
          { key: 'toDate', label: this.$t('custom-form.form-field.HOLIDAY.toDate'), type: 'date' }
        )
      }
      headers.push({ key: 'record.form.name.' + this.$i18n.locale, label: this.$t('custom-form.form.name') });
      if (this.formType === 'advance') {
        headers.push({ key: 'payout_type', label: this.$t('applications.advance.payout-type'), format: 'formatFromConfig', configKey: 'custom_form_constants', configParam: 'name' });
        headers.push({ key: 'amount', label: this.$t('applications.advance.amount'), format: 'formatDecimal', precision: 2 });
      }
      if (!this.onlyMyRequest) {
        headers.push({ key: 'record.approvals', label: this.$t('custom-form.form-field.HOLIDAY.approvals') });
      }
      headers.push({
        key: 'record.status_id',
        label: this.$t('custom-form.form-field.HOLIDAY.status_id'),
        format: 'formatFromConfig',
        configKey: 'custom_form_record_statuses',
        configParam: 'name',
        filterKey: 'statuses'
      });

      const actions = [];
      actions.push({
        label: this.$t('custom-form.record.action.view'),
        emit: 'editItem',
        bicon: 'eye-fill',
        class: 'btn-secondary'
      });
      if (!this.onlyMyRequest) {
        actions.push({
          label: this.$t('custom-form.record.action.download'),
          emit: 'downloadPdf',
          bicon: 'download',
          class: 'btn-download',
          ifconfig: { param: 'record.file', value: null, notCheck: true }
        });
        actions.unshift({
          label: this.$t('custom-form.record.action.reject'),
          emit: 'rejectHoliday',
          bicon: 'x',
          class: 'btn-error',
          ifconfig: { useFunc: true, func: this.canChangeStatus }
        });
        actions.unshift({
          label: this.$t('custom-form.record.action.approve'),
          emit: 'approveHoliday',
          bicon: 'check-all',
          class: 'btn-primary',
          ifconfig: { useFunc: true, func: this.canChangeStatus }
        });
        actions.unshift({
          label: this.$t('custom-form.record.action.process'),
          emit: 'processedHoliday',
          bicon: 'book-fill',
          class: 'btn-info',
          ifconfig: { useFunc: true, func: this.canMarkProcessed }
        });
      }

      headers.push({ key: 'actions', label: '', actions: actions });
      return headers;
    },
    holidayRejectedStatusId () {
      return this.$store.getters.getParamFromConfigByFilter('holiday_request_statuses', 'code', 'REJECTED', 'id');
    },
    holidayApprovedStatusId () {
      return this.$store.getters.getParamFromConfigByFilter('holiday_request_statuses', 'code', 'APPROVED', 'id');
    },
    formTypeCode () {
      if (this.form) {
        return this.$store.getters.getParamFromConfigByFilter('custom_form_types', 'id', parseInt(this.form.type_id), 'code');
      }
      return null;
    },
    tableExpandConfig () {
      return null;
    },
    requestPath () {
      let path = 'custom-form/form/form-requests/list';
      if (this.filterValues.filter) {
        path += '/' + this.filterValues.filter;
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
      }
      path += '?perPage=' + this.paging.limit + '&page=' + this.paging.page + '&order=created_at,DESC';
      path += '&requestType=' + this.formType;
      return path;
    },

    canViewAll () {
      return this.ApiRequest.userHasPermission('view-all-custom-forms');
    },

    canShow () {
      return this.ApiRequest.userHasPermission('view-custom-forms');
    },

    canShowCompany () {
      return this.ApiRequest.userHasPermission('view-custom-forms-company');
    },

    tableActions () {
      const actions = { download: false };
      if (this.onlyMyRequest === true && this.formType === 'advance') {
        actions.add = { title: this.$t('applications.advance.new') };
      }
      return actions;
    },
    applicationStatuses () {
      return this.$store.getters.getConfigOptions('custom_form_record_statuses', this.$i18n.locale);
    },
    companies () {
      return this.$store.getters.getConfigOptions('user_companies', this.$i18n.locale, 'disabled', '0');
    },

    recordFilterTypes () {
      const options = [{ value: 'MINE', text: this.$t('custom-form.record.filter.mine') }];
      options.push({ value: 'APPROVABLE', text: this.$t('custom-form.record.filter.approved') });
      options.push({ value: 'ATTACHEDAPPLICATIONS', text: this.$t('custom-form.record.filter.ATTACHED-APPLICATIONS') });
      if (this.canViewAll || this.canShow) {
        options.push({ value: 'ALL', text: this.$t('custom-form.record.filter.all') })
      }
      return options;
    },

    tableFilterConfig () {
      const filters = { }
      if (!this.onlyMyRequest) {
        filters.statuses = { type: 'checkbox', label: '', options: this.applicationStatuses, colOnlyFilter: true }
        filters.filter = {
          type: 'select',
          label: this.$t('custom-form.record.filter.title'),
          options: this.recordFilterTypes,
          valueField: 'value',
          class: 'col-md-2'
        }
        if (this.canViewAll || this.canShow) {
          filters.userFullName = {
            type: 'text',
            colOnlyFilter: false,
            label: this.$t('user.name'),
            class: 'col-md-2'
          }
        }
        if (this.canShowCompany && (this.formType === 'holiday' || this.ApiRequest.userHasPermission('view-advance-payment-requests-all-companies'))) {
          filters.companies = {
            type: 'checkbox',
            label: this.$t('company-structure.company.name'),
            colOnlyFilter: false,
            options: this.companies,
            class: 'col-md-8'
          }
        }
      }

      return filters;
    },

    approvedStatus () {
      return this.$store.getters.getParamFromConfigByFilter('custom_form_record_statuses', 'code', 'APPROVED', 'id');
    }
  },
  methods: {
    updateFilters () {
      this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
      this.getTableData();
    },

    canChangeStatus (item) {
      const userId = this.$session.get('userId');
      if (item.record && !item.record.waiting_approval_from) {
        return false;
      }
      return (item.record && item.record.waiting_approval_from === userId)
    },
    canMarkProcessed (item) {
      if ((this.ApiRequest.userHasPermission('edit-custom-forms')) &&
        item.record && item.record.status_id == this.approvedStatus
      ) {
        return true;
      }
      return false;
    },

    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.loading = true;
      const self = this;
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_POST, this.filterValues, function (response) {
        if (response.data) {
          self.tableItems = response.data;
          self.paging = response.paging;
          self.loading = false;
        }
      });
    },

    editItem (el, newtab = 0) {
      this.$refs.editElFrm.showModal(el);
      /*
      const routeData = this.$router.resolve({ name: 'menu.forms.record-edit', params: { formid: el.record.form_id, recordid: el.record.id } });
      if (newtab) {
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push(routeData.href);
      }
      */
    },
    addItem (el) {
      const routeData = this.$router.resolve({ name: 'menu.user.application-forms', query: { form_type: this.formType } });
      this.$router.push(routeData.href);
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    downloadPdf (el) {
      el.loading = true;
      this.$refs.tableview.$forceUpdate();
      const path = 'custom-form/form/view/' + el.record.form_id + '/records/view/' + el.record.id + '/download';
      const self = this;
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_DOWNLOAD, [], function (response) {
        if (response.error) {
          self.ApiRequest.displayErrorDialog(response, self);
        }
        el.loading = false;
        self.$refs.tableview.$forceUpdate();
      });
    },
    approveHoliday (el) {
      this.changeStatus(el, 1)
    },
    rejectHoliday (el) {
      this.changeStatus(el, 0)
    },
    changeStatus (el, status) {
      const record = el.record;
      const form = record.form;
      const path = 'custom-form/form/view/' + form.id + '/records/update-status/' + record.id;
      const row = this.tableItems.find(e => (e.id === el.id));
      if (row) {
        row.loading = true;
        this.$refs.tableview.$forceUpdate();
      }
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, { approve: status }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else {
          const messageBody = status
            ? 'custom-form.record.change-status-success-approved'
            : 'custom-form.record.change-status-success-rejected';
          this.ApiRequest.displaySuccessDialog(this, this.$t(messageBody), this.$t('custom-form.record.change-status-success-title'));
        }
        row.record.waiting_approval_from = response.data.record.waiting_approval_from;
        row.record.status_id = response.data.record.status_id + '';
        row.record.approvals = response.data.record.approvals;
        if (row) {
          row.loading = false;
          this.$refs.tableview.$forceUpdate();
        }
      });
    },
    processedHoliday (el) {
      const path = 'custom-form/form/change-status/' + el.record.id;
      el.loading = true;
      this.$refs.tableview.$forceUpdate();
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, { status: 'PROCESSED' }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else if (response.data) {
          el.record.status_id = response.data.record.status_id + '';
        }
        el.loading = false;
        this.$refs.tableview.$forceUpdate();
      });
    },

    clearFilters () {
      this.filterValues = {
        userFullName: '',
        statuses: [],
        filter: null,
        companies: []
      }
      this.getTableData();
    }
  },

  watch: {
    formType: {
      immediate: true,
      handler () {
        this.getTableData();
      }
    }
  },

  mounted () {
    const filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    if (this.onlyMyRequest) {
      this.filterValues.filter = 'MINE';
    } else if (this.canViewAll) {
      this.filterValues.filter = (filterValues.filter && filterValues.filter !== null) ? filterValues.filter : 'ALL';
      this.filterValues.companies = [this.$store.getters.getParamFromConfig('user_companies', this.$session.get('company_id'), 'code')];
    } else if (this.canShow) {
      this.filterValues = filterValues;
    } else {
      this.filterValues.filter = (filterValues.filter && filterValues.filter !== null) ? filterValues.filter : 'MINE';
    }

    this.getTableData();
    this.isMounted = true;
  }
}
