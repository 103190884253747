import ApproveList from '@/components/common/customform/form/notifications/approve/table/ApproveList.vue';

export default {
  name: 'NotificationEditList',
  props: {
    notifications: {
      type: Object,
      default: {}
    },
    recipients: {
      type: Object,
      default: {}
    },
    emails: {
      type: Array,
      default: []
    },
    customFormTypeId: {
      type: Number,
      default: null
    }
  },

  components: {
    ApproveList
  },

  data () {
    return {
      active: {
        company: {},
        department: {}
      },
      notificationData: Object.assign({
        submitter: { approval: null, rejection: null, cancel: null },
        smsSubmitter: { approval: null, rejection: null },
        template: {},
        recipients: {}
      }, this.notifications),
      totals: {}
    };
  },
  computed: {
    companies () {
      return this.$store.getters.getConfigOptions('user_companies', this.$i18n.locale, 'disabled', '0');
    },

    smsTemplateOptions () {
      return this.$store.getters.getConfigOptions(
        'sms_messages', null, null, null, false, false, false, ['title']
      ).map((el) => {
        return {
          value: el.value,
          text: el.title[this.$i18n.locale] + ' (' + el.code + ')'
        }
      });
    },

    emailTemplates () {
      const options = [{ value: null, text: '' }];
      this.emails.forEach((el) => {
        options.push({
          value: el.id,
          text: el.title[this.$i18n.locale] + ' (' + el.code + ')'
        });
      });
      return options;
    },
    recipientFields () {
      return [
        { key: 'name', label: this.$t('custom-form.notifications.name') },
        { key: 'position', label: this.$t('custom-form.notifications.position') },
        { key: 'recipient', label: this.$t('custom-form.notifications.receiver'), type: 'slot', slotcode: 'recipient' },
        { key: 'approve', label: this.$t('custom-form.notifications.approver'), type: 'slot', slotcode: 'approve' }
      ]
    },
    directItems () {
      return [
        {
          id: 'replacer',
          name: this.$t('custom-form.notifications.direct-replacer'),
          position: ''
        },
        {
          id: 'manager',
          name: this.$t('custom-form.notifications.direct-manager'),
          position: ''
        },
        {
          id: 'additionalApprove',
          name: this.$t('custom-form.notifications.additional-approvers'),
          position: ''
        }
      ]
    },
    directFields () {
      return [
        { key: 'name', label: this.$t('custom-form.notifications.name') },
        { key: 'recipient', label: this.$t('custom-form.notifications.receiver'), type: 'slot', slotcode: 'recipient' },
        { key: 'approve', label: this.$t('custom-form.notifications.approver'), type: 'slot', slotcode: 'approve' }
      ]
    },

    tableExpandConfig () {
      return { component: 'slot' };
    }
  },
  mounted () {
    // quick fix to solve laravel "object" casting issue
    // laravel converts empty objects as empty arrays which breaks checkboxes in some cases
    if (this.notificationData.template.length === 0) {
      this.notificationData.template = {};
    }
    if (this.notificationData.recipients.length === 0) {
      this.notificationData.recipients = {};
    }
    this.calculateTotals();
  },
  methods: {
    toggleCheck (type, companyId, departmentId, employeeId, value, action) {
      let updated = {};
      if (!value) {
        delete this.notificationData.recipients[companyId][departmentId][employeeId][action];
        if (!Object.keys(this.notificationData.recipients[companyId][departmentId][employeeId]).length) {
          delete this.notificationData.recipients[companyId][departmentId][employeeId];
        }
        if (!Object.keys(this.notificationData.recipients[companyId][departmentId]).length) {
          delete this.notificationData.recipients[companyId][departmentId];
        }
        if (!Object.keys(this.notificationData.recipients[companyId]).length) {
          delete this.notificationData.recipients[companyId];
        }
      } else {
        updated = this.BasicHelper.setNestedObject(this.notificationData, ['recipients', companyId, departmentId, employeeId, type], value);
      }
      this.notificationData = Object.assign({}, this.notificationData, updated);
      this.updateData();
    },
    selectEmailTemplate (companyId, departmentId, templateId) {
      let updated = {};
      if (templateId === null) {
        delete this.notificationData.template[companyId][departmentId];
        if (!Object.keys(this.notificationData.template[companyId]).length) {
          delete this.notificationData.template[companyId];
        }
      } else {
        updated = this.BasicHelper.setNestedObject(this.notificationData, ['template', companyId, departmentId], templateId);
      }
      this.notificationData = Object.assign({}, this.notificationData, updated);
      this.updateData();
    },
    toggleActive (key, level) {
      this.$set(this.active[level], key, this.active[level][key] === undefined ? true : !this.active[level][key])
    },
    hasValue (companyId, departmentId) {
      return this.BasicHelper.getNestedValue(this.notificationData, ['template', companyId, departmentId]);
    },
    isChecked (companyId, departmentId, employeeId, type) {
      return this.BasicHelper.getNestedValue(this.notificationData, ['recipients', companyId, departmentId, employeeId, type]) === true;
    },
    getSelectValue (companyId, departmentId) {
      return this.BasicHelper.getNestedValue(this.notificationData, ['template', companyId, departmentId]) || '';
    },
    getTotal (fields) {
      return this.BasicHelper.getNestedValue(this.totals, fields);
    },
    calculateTotals () {
      const totals = {};
      if (this.notificationData.recipients === undefined) {
        this.totals = {};
        return;
      }
      let notify, approve;
      for (const [companyId, departments] of Object.entries(this.notificationData.recipients)) {
        totals[companyId] = totals[companyId] || { notify: 0, approve: 0 };
        for (const [departmentId, checked] of Object.entries(departments)) {
          if (departmentId != 'direct') {
            const curEmployees = this.getEmployees(departmentId);
            Object.keys(checked).forEach(employeeId => {
              if (!curEmployees.find(el => el.id == employeeId)) {
                delete this.notificationData.recipients[companyId][departmentId][employeeId];
              }
            });
          }
          totals[companyId][departmentId] = totals[companyId][departmentId] || { notify: 0, approve: 0, total: 0 };
          notify = Object.values(checked).filter((e) => { return e.notify }).length;
          approve = Object.values(checked).filter((e) => { return e.approve }).length;
          totals[companyId].notify += notify;
          totals[companyId].approve += approve;
          totals[companyId][departmentId].notify += notify;
          totals[companyId][departmentId].approve += approve;
          totals[companyId][departmentId].total += (notify + approve);
        }
      }
      this.totals = Object.assign({}, totals);
    },
    getDepartments (companyId) {
      let departments = this.$store.getters.getItemsFromConfigWithFilter('company_departments', 'company_id', companyId);
      departments = Object.values(departments).filter(dep => !dep.deleted);
      return departments;
    },
    getEmployees (departmentId) {
      if (!this.recipients[departmentId]) {
        return [];
      }
      return Object.values(this.recipients[departmentId]);
    },
    updateData () {
      this.$emit('change', this.notificationData);
      this.calculateTotals();
    },
    getRowClass (item) {
      return item.isDisabled ? 'disabled' : null;
    }
  }
}
